<template lang="pug">
  b-row.mb-5
    b-col

      b-card.manager-machines-list.card-white.card-no-gutters.shadow(no-body)
        b-card-header.bg-transparent.border-0
          wc-tables-header(
            :title="$t('activerecord.models.machine.other')"
            :action-new='policies.new'
            :route-new="{ name: 'manager-machines-new' }"
            @click-data-clean-fetch='dataCleanFetch'
            @click-data-fetch='dataFetch')

          .text-small.text-muted(v-if='policies.new === false')
            i.fal.fa-exclamation-triangle
            |  {{ $t('machine.max_machines') }}

        b-card-body
          b-row
            b-col
              wc-tables-filter(
                :filter.sync='filter'
                :p-page.sync='pagination.pPage'
                :p-page-options='pagination.pPageOptions'
                @submit='dataFetch'
                @submit-clear='filterClear')

          b-row
            b-col
              transition(name='fade')
                wc-tables-wrapper-default(
                  :ref='wns'
                  :items='items'
                  :fields='fields'
                  :sort-by.sync='sorting.sortBy'
                  :sort-desc.sync='sorting.sortDesc'
                  :is-loading='isLoading'
                  :action-delete='actionDestroy'
                  @row-clicked='rowClicked'
                  @row-delete='rowDelete'
                  @sort-changed='sortingChanged'
                  attr-active)
                  template(#head(attributes.online))
                    i.fal.fa-fw.fa-wifi
                  template(v-slot:cell(attributes.online)='data')
                    span(v-if='data.item.attributes.online')
                      i.fal.fa-fw.fa-wifi.text-success
                    span(v-else)
                      i.fal.fa-fw.fa-wifi-slash.text-warning
                  template(v-slot:cell(attributes.organization_name)='data')
                    | {{ $getDeep(included.find(x => x.type === 'organization' && x.id === $getDeep(data.item, 'relationships.organization.data.id')), 'attributes.name') }}

                  template(v-slot:cell(attributes.model_name)='data')
                    | {{ $getDeep(included.find(x => x.type === 'model' && x.id === $getDeep(data.item, 'relationships.model.data.id')), 'attributes.name') }}

                  template(v-slot:cell(attributes.firmware_version)='data')
                    | {{ $getDeep(included.find(x => x.type === 'firmware' && x.id === $getDeep(data.item, 'relationships.firmware.data.id')), 'attributes.version') }}

                  template(v-slot:cell(attributes.state)='data')
                    b-badge.state.p-2(
                      :variant="$wc.conf.states.machine.variants[data.item.attributes.state]"
                      pill)
                      i.fa-fw(:class="$wc.conf.states.machine.icons[data.item.attributes.state]")
                      |  {{ $t('machine.states.' + data.item.attributes.state) }}

                  template(v-slot:cell(actions)='data')
                    b-dropdown.form-buttons-actions-item-menu(
                      toggle-tag='div'
                      toggle-class='p-0 text-muted shadow-none'
                      variant='transparent'
                      menu-class='border-0 p-0'
                      size='sm'
                      right
                      no-caret)
                      template(#button-content)
                        i.fal.fa-fw.fa-2x.fa-ellipsis-h.text-muted
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.available"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'available', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.machine.icons.available")
                        |  {{ $t('machine.menus.to_available') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.maintenance"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'maintenance', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.machine.icons.maintenance")
                        |  {{ $t('machine.menus.to_mantain') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.retired"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'retired', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.machine.icons.retired")
                        |  {{ $t('machine.menus.to_retire') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.draft"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'draft', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.machine.icons.draft")
                        |  {{ $t('machine.menus.to_redraft') }}
                      b-dropdown-item(
                        v-if="data.item.attributes.policies.deactivated"
                        link-class='py-3'
                        :disabled='data.item.isLoading'
                        @click.stop.prevent="itemTransition(data.item, 'deactivated', data.index)")
                        i.fa-fw.text-muted(:class="$wc.conf.states.machine.icons.deactivated")
                        |  {{ $t('machine.menus.to_deactivate') }}

        b-card-footer.border-0.bg-white
          wc-tables-footer(
            :total-items='pagination.totalItems'
            :p-page='pagination.pPage'
            :c-page.sync='pagination.cPage')

        loading(
          :active.sync='isLoading'
          :can-cancel-esc='true'
          :on-cancel='onCancel')
</template>

<script>
import Table from '@common/table'
import apiMachines from '@services/api/manager/machines'

export default {
  name: 'manager-machines-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'mac',
    },
  },
  methods: {
    apiParams(item = {}) {
      return {
        list: [{}, this.sortingParams],
        transitionAvailable: [
          { id: this.$getDeep(item, 'attributes.sid'), transition: 'available' },
        ],
        transitionMaintenance: [
          { id: this.$getDeep(item, 'attributes.sid'), transition: 'maintenance' },
        ],
        transitionRetired: [{ id: this.$getDeep(item, 'attributes.sid'), transition: 'retired' }],
        transitionDraft: [{ id: this.$getDeep(item, 'attributes.sid'), transition: 'draft' }],
        transitionDeactivated: [
          { id: this.$getDeep(item, 'attributes.sid'), transition: 'deactivated' },
        ],
        destroy: [{ id: this.$getDeep(item, 'attributes.sid') }],
      }
    },
    routeParams(item) {
      return {
        edit: [{ name: 'manager-machines-edit', params: { machine_id: item.attributes.sid } }],
      }
    },
    itemTransition(item, transition, index) {
      if (!item.attributes.policies[transition]) return
      this.$mergeDeep(this.items[index], true, 'isLoading')
      this.apiRequest = this.apiRequestBuilder(
        'transition',
        item,
        `transition${transition.charAt(0).toUpperCase()}${transition.slice(1)}`
      )
      this.apiRequest.promise
        .then((response) => {
          this.$mergeDeep(this.items[index], response.data)
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.$mergeDeep(this.items[index], false, 'isLoading')
          this.apiCallback('item-transition-success')
          return true
        })
        .catch((response) => {
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.$mergeDeep(this.items[index], false, 'isLoading')
          this.apiCallback('item-transition-error')
        })
      this.apiCallback('item-transition')
    },
    channelSubscribe() {
      if (!this.$cable._channels.subscriptions['Api::V1::Manager::MachinesChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Manager::MachinesChannel',
        })
      }
    },
  },
  channels: {
    'Api::V1::Manager::MachinesChannel': {
      connected() {
        this.cable.machinesConnected = true
      },
      rejected() {
        this.cable.machinesConnected = false
      },
      received(response) {
        const item = this.items.find((x) => x.id === response.id)
        if (item) this.$setDeep(item, 'attributes.online', response.online)
      },
      disconnected() {
        this.cable.machinesConnected = false
      },
    },
  },
  data() {
    return {
      apiBase: apiMachines,
      actionRowClicked: 'edit',
      cable: {
        machinesConnected: false,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'machines.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.online',
          label: this.$t('activerecord.attributes.machine.online'),
          sortable: true,
          attr: 'machines.online',
        },
        {
          key: 'attributes.serial',
          label: this.$t('activerecord.attributes.machine.serial'),
          sortable: true,
          attr: 'machines.serial',
        },
        {
          key: 'attributes.organization_name',
          label: this.$t('activerecord.models.organization.one'),
          sortable: true,
          attr: 'organizations.name',
        },
        {
          key: 'attributes.model_name',
          label: this.$t('activerecord.attributes.machine.model'),
          sortable: true,
          attr: 'machine_models.name',
        },
        {
          key: 'attributes.firmware_version',
          label: this.$t('activerecord.models.firmware.one'),
          sortable: true,
          attr: 'firmwares.version',
        },
        {
          key: 'attributes.version',
          label: this.$t('activerecord.attributes.machine.version'),
          sortable: true,
          attr: 'machines.version',
        },
        {
          key: 'attributes.state',
          label: this.$t('activerecord.attributes.shared.state'),
          sortable: true,
          attr: 'machines.state',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'machines.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'machines.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'machines.created_at',
        },
        {
          key: 'actions',
          label: this.$t('shared.menus.actions'),
          class: 'text-right',
        },
        {
          key: 'delete',
          label: this.$t('shared.actions.delete'),
          class: 'text-right',
        },
      ],
    }
  },
  mounted() {
    this.channelSubscribe()
  },
}
</script>
